body {
  background-color: #111111;
}

.techBadge {
  padding: 4px;
}

.MuiImageListItem-img {
  transition: 0.5s all ease-in-out;
}

.MuiImageListItem-img:hover {
  transform: scale(1.1);
}
